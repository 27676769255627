.navbar {
  background-color: #1c3f6a;
}

.berg-red {
  color: #f00008;
}

.berg-yellow {
  color: #ffff35;
}

.navbar-logo {
  height: 20px;
}
